.Home {

    background: #f4f4f4;

    .title {
        text-align: center;
        margin-top: 4rem;
    }

    .inPageNav {
        text-align: center;
        margin-top: 3rem;

        a {
            font-size: 1.6rem;
            color: #777;
            border: 1px solid transparent;
            padding: 8px 16px;
            border-radius: 1rem;
            margin: 1px;
            display: inline-block;

            &:hover,
            &.active {
                color: #fff;
                background: #333;
            }
        }
    }

    .HomeContent {
        @media all and (max-width: 1240px) {
            padding-left: 2rem; 
            padding-right: 2rem;
        }

        & > h2 {
            text-align: left;
            line-height: 1;
            background: #777;
            padding: 2rem;
            color: #fff;
            font-size: 2.4rem;
            max-width: 1200px;
            margin: 0 auto;
            margin-top: 4rem;
            border-top-right-radius: 1rem;
            border-top-left-radius: 1rem;
            font-weight: 700;
    
            @media all and (max-width: 650px) {
                font-size: 2.2rem
            }
        }
    }
}
