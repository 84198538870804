
.form-control {
    display: block;
    margin: 1rem 0;

    &--invalid {
        color: #E04C4C;

        input, textarea, select {
            border-color: #E04C4C ;
            background: #ffd1d1;
        }
    }

    &--checkbox {
        display: flex;
        align-items: center;

        input {
            width: auto !important;
            margin-right: 1.6rem;
            margin-top: 4px;
        }

        label {
            margin: 0 !important;
        }

        &--center {
            justify-content: center;
        }

        &--left {
            justify-content: flex-start;
            margin-left: 2rem;
        }
    }

    &--radio {
        display: flex;
        align-items: center;

        input {
            width: auto !important;
            margin-right: 1rem;
        }

        label {
            margin: 0 !important;
        }

        &--center {
            justify-content: center;
        }

        &--left {
            justify-content: flex-start;
            margin-left: 2rem;
        }
    }

    label {
        font-weight: bold;
        margin-bottom: 0.5rem;
        display: block;
    }

    input, textarea, select {
        width: 100%;
        font: inherit;
        border: 0;
        border-bottom: 1px solid #ccc;
        background: #f8f8f8;
        padding: 1rem;
        display: block;
    }

    input:focus,
    textarea:focus,
    select:focus {
        outline: none;
        background: #ebebeb;
        border-color: #510077;
    }

    p {
        font-size: 1.2rem;
        margin-top: .8rem;
    }
}







// // SELECT
 
// .form-input-select {
//     position: relative; 
//     min-width: 14rem;
    
//     &::before {
//         content: "\F107";
//         font-family: "Font Awesome 5 Pro";
//         font-weight: 300;
//         font-size: 2rem;
//         right: 2rem;
//         position: absolute;
//         top: .7rem;
//         color: $color-gray-medium;
//         pointer-events: none;
//     }

//     select {
//         @include text-input;
//         display: inline-block;
//     }
// }


// .add-listing .form-input-select {
//     min-width: initial;
// }


// // OPTIONS

// .form-input-full {
//     border: 1px solid $color-border !important;
//     border-radius: $default-border-radius;
// }



// // SINGLE & MULTIPLE CHECKLISTS

// .form-input-lists {
//     margin: .8rem 0;
//     cursor: pointer;

//     & input {
//         visibility: hidden;
//         margin-left: -1rem;

//         &[type=radio]:checked + label {
//             color: $color-gray-dark;
//         }

//         &[type=radio]:checked + label i {
//             color: $color-secondary;
//             font-weight: 400;
//             margin-right: .8rem;
//         }

//         &[type=checkbox]:checked + label {
//             color: $color-gray-dark;
//         }

//         &[type=checkbox]:checked + label i {
//             color: $color-secondary;
//             font-weight: 400;
//             margin-right: .8rem;
//         }
//     }

//     & label {
//         color: $color-gray-dark;
//         font-size: 1.5rem;
//         font-weight: 400;
//         cursor: pointer;
//         line-height: 1.5rem;

//         & i {
//             color: $color-gray-light;
//             margin-right: 1rem;
//             cursor: pointer;
//             border: 1px solid $color-gray-medium !important;
//             padding: .3rem !important;
//             border-radius: .3rem !important;
//         }
//     }

//     &:hover label {
//         color: $color-gray-dark;
//     }

//     &:hover label i {
//         color: $color-gray-light;
//     }

//     &_row {
//         display: inline-block;
        
//         &:not(:last-child) {
//             margin-right: 1.4rem;
//             margin-top: 0;
//             margin-bottom: 0;
//         }
//     }
// }

// textarea {
//     resize: none !important;
// }


// .modal_body_functions_agree-terms {
//     padding: 1rem 2rem;
// }

// .modal_body_functions_agree-terms label span i {
//     border: 1px solid $color-gray-medium !important;
//     color: #fff;
//     padding: .3rem;
//     border-radius: .3rem;
// }