.profileDetails {

    .profileId {
        display: inline-flex;
        margin-top: -9.5rem;
        margin-bottom: 2rem;
        position: relative;
        pointer-events: none;
    
    
        @media all and (max-width: 800px) {
            margin-top: 0 !important;
        }
    
        img {
            width: 13rem;
            height: 13rem; 
            border-radius: 50%; 
            display: block;
            margin-right: 1.5rem;
    
        }
    
        button {
            position: absolute;
            bottom: 1rem;
            left: 5rem;
            color: #fff;
            font-size: 3rem;
        }
    
        h3 {
            margin-top: 8rem;
            font-size: 2rem !important;
            font-weight: 600;
            line-height: 1;
    
            @media all and (max-width: 800px) {
                margin-top: 4.2rem !important;
            }
    
            span {
                font-size: 1.3rem;
                line-height: 1;
                display: block;
                margin-top: .4rem;
                color: #555;
            }
        }
    }

    .profileNotification {
        margin: 0 auto;
        padding: 15px 30px;
        color: #fff;
        background: #2c3e50;
        border-radius: 1rem;
        color: #fff;
        margin-bottom: 2rem;
    }

    

    h4 {
        margin-bottom: 2rem;
    }

    .grid {
        display: grid; 
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 20px;
        margin-bottom: 4rem;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4rem;
 
        @media all and (max-width: 1150px) {
            flex-wrap: wrap;
        }
    }

    &Box {
        border: 1px solid #b5b5b5;
        border-radius: 1rem;
        padding: 2rem; 
        text-align: center;
        // height: 26rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &Left {
            align-items: flex-start;
            justify-content: left;
            text-align: left;
            padding: 2rem;
        }

        .pending {
            tr {
                td {
                    border: 1px solid #e7e7e7;
                    padding: 6px 12px;

                    &:last-child {
                        text-align: right;
                        font-weight: 700;
                    }
                }
            }
        }

        i {
            display: block;
            font-size: 4rem;
            color: #999;
            margin-bottom: 2rem;

            &.fa-user-check-verified,
            &.fa-shield-check-verified {
                color: #6EC568;
            }

            &.fa-user-check-unverified,
            &.fa-shield-check-unverified {
                color: #ddd;
            }
        }

        p {
            font-size: 1.6rem;
            line-height: 1.3;
        }

        span {
            display: block;
            font-size: 3rem;
            line-height: 1;
            color: #444;
            margin-bottom: .6rem;
            margin-top: .6rem;

            span {
                font-size: 1.6rem;
                display: inline-block;
            }
        }

        button, a{
            display: inline-block;
            margin-top: 2rem;
            color: #444;

            &:hover {
                color: #fff;
            }
        }

        &.equityInvestments {
            background: #00b4db;
            background: linear-gradient(to right bottom,#0083b0,#00b4db);
            color: #fff!important;
        }

        &.revenueInvestments {
            background: #06beb6;
            background: linear-gradient(to right bottom,#48b1bf,#06beb6);
            color: #fff!important;
        }

        &.enterpriseValue {
            background: #00b4db;
            background: linear-gradient(to right bottom,#0083b0,#00b4db);
            color: #fff!important;
        }

        &.equityInvestments,
        &.revenueInvestments {
            i {
                color: #fff;
            }
        }

        ul {
            text-align: left;
            margin-top: 2rem;

            li {
                margin-top: 1rem;
                display: flex;

                a {
                    margin-top: 0;

                    &:hover {
                        color: #444;
                    }
                }

                i {
                    font-size: 2rem;
                    display: inline-block;
                    margin-bottom: 0;
                    margin-right: 1rem;  
                }

                p {
                    font-size: 1.3rem;
                    line-height: 2rem;
                }
            }
        }
    }

    &Notifications {

        margin-bottom: 4rem;

        &Box {
            background: #fe5196;
            background: -webkit-linear-gradient(to right bottom,#f77062,#fe5196);
            background: -webkit-gradient(linear,left top,right bottom,from(#f77062),to(#fe5196));
            background: -webkit-linear-gradient(left top,#f77062,#fe5196);
            background: -o-linear-gradient(left top,#f77062,#fe5196);
            background: linear-gradient(to right bottom,#f77062,#fe5196);

            padding: 2rem;
            display: flex;
            align-items: flex-start;
            border-radius: 1rem;
            flex-wrap: wrap;
            
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
            

            i {
                color: #fff;
                font-size: 3.2rem;
                width: 50px;
            }

            p {
                color: #fff;
            }

            .flex-button {
                width: 100%;
                margin-top: 2rem;
                margin-left: 18%;

                button {
                    color: #fff;
                    border: 1px solid #fff;
                }
            }
        }
    }
}

.profileDetailsPresentation {
    border: 1px solid #ccc;
    border-radius: 10px; 
    overflow: hidden;
}

.pdfSize {
    height: 750px;
}