.modal {    
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 1rem;
    overflow: hidden;
    position: fixed; 
    z-index: 999999;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    
    &--small {
      max-width: 40rem;

      @media all and (max-width: 450px) {
        max-width: 90%;
      }
    }

    &--regular {
      max-width: 80rem;

      
      @media all and (max-width: 850px) {
        max-width: 90%;

        .modal__content {
          height: 70vh;
          overflow-y: scroll;
        }
  
      }
    }

    &--large {
      width: 90%;
      max-width: 1200px;

      .modal__content {
        height: 70vh;
        overflow-y: scroll;
      }
    }

  }
  
  .modal__header {
    width: 100%;
    padding: 1rem 1.4rem;
    background: #f8f8f8;
    color: #444444;
    display: flex; 
    justify-content: space-between;
    align-items: center;
  }
  
  .modal__content {
    padding: 2rem 3rem;
  }
  
  .modal__footer {
    padding: 1rem 0.5rem;
  }
  
  .modal-enter {
    opacity: 0;
  }
  
  .modal-enter-active {
    opacity: 1;
    transition: all 200ms;
  }
  
  .modal-exit {
    opacity: 1;
  }
  
  .modal-exit-active {
    opacity: 0;
    transition: all 200ms;
  }



  .button-content-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;

    @media print {
      display: none;
    }
  }
  



// .modal_background {
//     position: fixed; 
//     width: 100%;
//     height: 100%;
//     background: rgba(0,0,0,0.4);
//     z-index: 99999999;
//     top: 0;
//     left: 0;
// }

// .modal_box { 
//     overflow: hidden;
//     position: fixed;
//     border: 1px solid #ededed;
//     border-radius: 1rem;
//     background: #fff;
//     z-index: 999999999; 

//     -webkit-box-shadow: 0px 0px 19px -2px rgba(0,0,0,0.27);
//     -moz-box-shadow: 0px 0px 19px -2px rgba(0,0,0,0.27);
//     box-shadow: 0px 0px 19px -2px rgba(0,0,0,0.27);
    
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     &_header {
//         padding: 1.2rem;
//         border-bottom: 1px solid #ededed;
//         position: relative;

//         button {
//             position: absolute;
//             font-size: 2.4rem;
//             right: 2rem;
//             top: .8rem;
//         }

//         p {
//             font-weight: bold;
//         }
//     }

//     &_footer {
//         border-top: 1px solid #ededed;
//         text-align: center;
//         padding: 2rem 0;

//         .button-text {
//             padding-left: 1rem;
//             padding-right: 0;
//             text-transform: capitalize;
//             font-size: 1.3rem;
//         }
//     }

//     .subscription_agreement {
//         height: 100%;

//         .subscription_agreement-document {
//             overflow-y: scroll;
//             height: 100%;
//         }
//     }


//     &_subscription {
//         width: 80rem;

//         .modal_box_content {
//             height: 40rem;
//         }
//     }

//     &_login {
//         width: 32rem;
//     }

// }