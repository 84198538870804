.payment_info {
    padding: 4rem;
    background: #fff;

    h4 {
        margin-bottom: 0rem;
    }

    &_selection {
        margin-bottom: 1rem;

        div {
            display: inline-block;
            padding-left: 0 !important;
        }
    }

    &_box {
        width: 100%;
        margin-bottom: 2rem;
        border: 1px solid #ededed;
        padding: 2rem;
        border-radius: 1rem;

        table {
            margin-top: .5rem;

            tr {
                td {
                    padding: .5rem 1rem .5rem 0;
                }
            }
        }

        input {
            max-width: 30rem;
            margin-bottom: 2rem;
        }

        .button_check {

            border-radius: 1rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            border: 1px solid #b5b5b5;

            i {
                font-size: 2rem;
                margin-bottom: .5rem;
            }
        }
    }
}