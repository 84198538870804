.admin {
    background: #f4f4f4;
    padding-bottom: 4rem;

    h4 {
        @media all and (max-width: 1100px) {
            padding: 0 !important;
        }
    }

    .create-account-content-box {
        @media all and (max-width: 1100px) {
            width: 100% !important;
            margin: 0 !important;
        }
    }

    &-content {
        padding: 2rem 4rem;
    }

    .create-account-content-box {
        width: 100%;
    }

    h4 {
        width: 100% !important;
        margin-top: 0 !important;
    }
} 


