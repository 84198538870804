.verification_process {
    padding: 4rem;
    background: #fff;

    h4 {
        margin-bottom: 2rem;
    }

    ul {
        margin-top: 2rem;
        list-style-type: disc;
        margin-left: 3rem;
    }


    &_box {
        width: 100%;
        border: 1px solid #ededed; 
        padding: 2rem; 
        border-radius: 1rem;

        .modal_body_functions_agree_terms {
            padding-left: 0;
            padding-bottom: 0;
            padding-top: 0;
            
            label {
                font-size: 1.3rem;
            }
        }

        .divider {
            margin-top: 1rem !important;
        }

        button, a {
            margin-right: 1rem !important
        }

        a {
            margin-top: 1rem !important;
            margin-bottom: 1rem;
        }

        .title {
            font-size: 1.6rem;
            font-weight: 700;
        }
    }

    &_letter {
        text-align: center;

        button {
            display: inline-block;
            border: 1px solid #b5b5b5;
            color: #999;
        }

        i {
            font-size: 5rem;
            display: block;
            margin-top: 4.4rem;
            color: #999;
        }
    }

    &_verified {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.6rem;
        text-align: center;

        i {
            font-size: 4rem;
            color: #6EC568;
            padding-right: 1rem;
            vertical-align: middle;
            margin-bottom: 1rem;
        }

        h3 {
            margin-top: 4rem;
        }
    }

    .divider {
        border-bottom: 1px solid #ededed;
        margin-top: 2rem !important; 
        margin-bottom: 1rem;
    }
}