.verticals {
    // display: grid;
    // grid-template-columns: repeat(auto-fill,minmax(50rem,1fr));
    // grid-gap: 4rem;
    margin-top: 3rem;
    background: #fff;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    // padding: 4rem;
    max-width: 1200px;
    margin: 0 auto 4rem auto;
    overflow: hidden;
    border: 1px solid #e7e7e7;
    
    .vertical {
        img {
            width: 100%;
            display: block;
        }

        &-desc {
            background: #fff;
            padding: 4rem;
            position: relative;

            box-shadow: 0px 0px 39px -4px rgba(0,0,0,0.49);
            -webkit-box-shadow: 0px 0px 39px -4px rgba(0,0,0,0.49);
            -moz-box-shadow: 0px 0px 39px -4px rgba(0,0,0,0.49);

            .dataTable {
                margin-top: 3rem;
            }

            td {
                border: 1px solid #e7e7e7;
                padding: 1.4rem;
                font-size: 1.8rem;
                vertical-align: middle;

                i {
                    font-size: 2.5rem;
                }
            }

            .infoTable {
                margin-top: 1rem;
                margin-bottom: 1rem;

                td {
                    padding: 1rem;
                    font-size: 1.3rem;
                }
            }
        }
    }

    .verticalHeader {
        display: flex;
        align-items: center;

        .verticalHeaderIcon {
            width: 75px;
            height: 75px;
            border: 1px solid #ccc;
            border-radius: 1rem;
            margin-right: 2rem;
            display: flex; 
            align-items: center;
            justify-content: center;
            font-size: 3.5rem;
        }

        .verticalHeaderTitle { 
            h3 {
                line-height: 1;
                margin-bottom: 4px;
                font-weight: 700;
            }

            p {
                font-size: 1.4rem;
            }
        }
    }
}


