.homeTech {
    margin-top: 3rem;
    background: #333;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 4rem;
    max-width: 1200px;
    margin: 0 auto 4rem auto;
    color: #fff;

    p {
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 2rem;

        &:first-child {
            font-size: 24px;
            line-height: 1.3;
        }
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 4rem;

        i {
            margin: 8px;
            font-size: 4.5rem;
        }
    }
}