
.adminContentNav {

    margin-bottom: 4rem;
    margin-top: 1rem;

    @media all and (max-width: 700px) {
        display: flex; 
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    a {
        @media all and (max-width: 700px) {
            margin: .5rem !important;
            width: 40%;
            text-align: center;
        }
    }

}