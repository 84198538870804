.investmentCard {
    border: 1px solid #e7e7e7;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;

    h3 {
        margin-bottom: 0;
        display: block;
        margin-top: 0;
        background: #444;
        font-weight: 300;
        padding: 10px 20px;
        color: #fff;
        font-size: 14px;

        &.rev-nd2-h3 {
            background: #DDD !important;
            color: #444 !important;
        }
    }

    table {
        margin-bottom: 0;
        border-collapse: collapse;
        width: 100%;

        tr {
            &:not(:last-child) {
                td {
                    border-bottom: 1px solid #e7e7e7;
                }
            }
        }

        td {
            
            padding: 10px 20px;
            font-size: 13px;

            &:first-child {
                color: #999;
                width: 140px;
            }

            

            &.green {
                color: #60AE5B;
                font-weight: 700;
                font-size: 14px;
            }
        }
    }
}

.equityValue {
    td {
        font-weight: bold;
        background-color: #f8f8f8;
        font-size: 14px !important; 
    }
}