@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');


*,
*::after,
*::before   {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

:focus {
	outline: none;
}

html {
    font-size: 62.5%; /* For rem measurements always use the root.  */
}

body {
    box-sizing: border-box;
    min-width: 440px;
    font-family: 'Lato', sans-serif;
    color: #444;
    font-weight: 400;
    line-height: 1.6;
    font-size: 1.6rem;
    background: #f4f4f4;
    padding-bottom: 4rem;
}

ol, ul {
	list-style: none; 
}

table {
	border-collapse: collapse;
	border-spacing: 0;
} 

::placeholder {
	color: #b5b5b5
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input:not([type=checkbox]):not([type=radio]) {
    -webkit-appearance: none;
}

textarea {
    -webkit-appearance: none !important;
}

button {
    background: transparent;
    border: 0;
    cursor: pointer;
}


/* TYPOGRAPHY */

body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    line-height: 1.6;
    font-size: 1.3rem;
    color: #444444; 
}

h1 {
	font-weight: 300;
    font-size: 4.4rem;
    line-height: 1.2;
}

h2 {
	font-weight: 300;
	font-size: 3.6rem;
}

h3 {
	font-weight: 300;
	font-size: 2.8rem;
}

h4 {
    font-weight: 600;
	text-transform: uppercase;
	font-size: 1.8rem;
}


a {
    text-decoration: none;

    &:link, 
    &:visited {
        color: #469AD4;
        text-decoration: none;
    }

    &:hover {
        color: #425CAF;
    }
}


strong, .strong {
	font-weight: 700;
}

.info {
    color: red;
    font-style: italic;
    margin-top: 1rem;

    i {
        margin-right: .8rem;
    }
}

.title-case {
    text-transform: capitalize;
}

.signature-font {
    font-family: 'Reenie Beanie', cursive;
    font-size: 3rem;
}

/* LAYOUT */

.inline-block {
    display: inline-block;
}

.row {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 

    .form-control {
        margin: 0;
    }

    &:not(:last-child) {
        margin-bottom: 4rem;
    }
}

.row-small {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr)); 
}


/* WEBSITE MESSAGES */

.success-message {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    background: #60AE5B;
    color: #fff;
    padding: 10px 10px 10px 20px;
    border-radius: 10px;

    p {
        margin-right: 20px;
    }
}










/* PDF VIEWER CUSTOM */

.viewer-toolbar-right {
    display: none !important;
}

.viewer-toolbar-center {
    display: none !important;
}

.viewer-page-layer {
    width: 100% !important;
}

.viewer-canvas-layer {
    width: 100% !important;

    canvas {
        width: 100% !important;
        transform: scale(1) !important;
    }
}

.viewer-layout-container {
    border: 0px !important;
}

.viewer-toolbar-current-page-input {
    margin-right: 8px;
}

.viewer-inner-page {
    padding: 0 !important;
}

@page { size: auto;  margin: 5mm; }


.pdf-container {
    display: flex;
    justify-content: center;
    user-select: none;
  }
  
  .controls {
    margin: 20px;
  }
  
  @media print {
    .pdf-container {
      display: none;
    }
  }

.react-pdf__Page__textContent {
    display: none !important;
}






















// // BOX

// $default-border-radius: 1rem;
// $profile-image-border-radius: 50%;


// .centerAlign {
// 	display: block;
// 	margin: 0 auto;
// }

// .block-align-center {
// 	display: block;
// 	margin: 0 auto;
// }



// .fnts {
// 	font-size: 1.1rem; 
// }

// .txtc {
// 	text-align: center;
// }



// .mgb0 {
// 	margin-bottom: 0 !important;
// }

// .mgt0 {
// 	margin-top: 0 !important;
// }


// .mgb-small {
// 	margin-bottom: 1rem !important;
// }

// .mgl-small {
// 	margin-left: 1rem !important;
// }

// .mgt-small {
// 	margin-top: 1rem !important;
// }

// .mgt-medium {
// 	margin-top: 2rem !important;
// }

// .mgb-medium {
// 	margin-bottom: 2rem !important;
// }

// .mgb-0 {
// 	margin-bottom: 0 !important;
// }

// .mgr-small {
//     margin-right: 3rem;
// }

// .mgt-small {
//     margin-top: 3rem;
// }


// .disp-inblk {
// 	display: inline-block !important; 
// }

// .full-width {
// 	width: 100% !important;
// 	max-width: 100% !important;
// }

// .w50 {
// 	width: 50% !important;
// }

// .inline-block {
// 	display: inline-block !important;
// }

// .display-block {
// 	display: block;
// }

// .align-center {
// 	text-align: center;
// }

// .pagination {
// 	text-align: center;

// 	button {
// 		margin: 0 .2rem;
// 		border: 1px solid $color-primary;
// 		padding: .5rem 1rem;
// 		border-radius: 5px;
// 		background: $color-primary; 
// 		color: $color-white;
// 		font-weight: 300;

// 		&:hover {
// 			color: #fff !important;
// 			background: $color-primary-dark; 
// 		}

// 		@media all and (max-width: 500px) {
// 			display: none;
// 		}
// 	}

// 	span {
// 		margin: 0 .4rem;

// 		@media all and (max-width: 500px) {
// 			display: none;
// 		}
// 	}

// 	&_current {
// 		cursor: text !important;
// 		border: 1px solid $color-gray-dark !important;
// 		background: $color-gray-dark !important;
// 	}

// 	button.pagination_previous,
// 	button.pagination_next {
// 		@media all and (max-width: 500px) {
// 			display: inline-block;
// 			padding: 1rem 2rem;
// 			width: 14rem;
// 		}
// 	}
// }


// .show-box {
// 	@media all and (max-width: 750px) {
// 		opacity: 1;
// 		height: 100%;
// 	}   
// }

// .hide-box {
// 	@media all and (max-width: 750px) {
// 		opacity: 0;
// 		height: 0;
// 	}   
// }


// .white-text {
// 	color: $color-white;
// }


// .create-account-content-box .fix-sign-up-checkbox label span:first-child {
// 	display: inline !important;
// 	float: none !important;
// }

// .create-account-content-box .fix-sign-up-checkbox label span:last-child {
// 	line-height: 1.4;
// 	width: auto !important;
// 	display: inline !important;
// 	margin-left: 0rem !important;
// 	margin-top: -1.6rem;
// }







// // FORMS & BUTTONS

// @mixin button {
//     text-transform: uppercase;
//     border-radius: 5rem;
//     cursor: pointer;
//     transition: all 0.3s ease-in-out;
//     padding: 1.2rem 3.2rem;
//     font-size: 1.3rem;
// }

// @mixin close-x-button {
//     position: absolute;
//     display: block;
//     top: 1.5rem;
//     right: 2rem;
//     font-size: 3rem;
//     line-height: 3rem;
//     cursor: pointer;
//     color: $color-primary;

//         &:hover {
//             color: $color-primary-dark;
//         }
// }

// @mixin modal-x-button {
//     position: absolute;
//     display: block;
//     top: 0rem;
//     right: 0rem;
//     padding: .2rem 1rem;
//     font-size: 2.2rem;
//     line-height: 3rem;
//     cursor: pointer;
//     color: $color-primary;

//     &:hover {
//         color: $color-primary-dark;
//     }
// }



// @mixin text-input {
//     display: block;
//     width: 100%;
//     padding: 1.6rem;
//     border: 0;
//     background: none;
//     color: $color-gray-dark;
//     border-bottom: 1px solid $color-border;
//     font-family: 'Open Sans', sans-serif;
// }




// // DESIGN 

// @mixin modal {
//     z-index: 500;
//     background-color: $color-white;
//     border-radius: 1rem;
//     transition: all 0.5s ease-in-out;
// }

// @mixin center-box {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
// }

// @mixin modal-center-box {
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
// }

// @mixin clearfix { 
//     &::after { 
//         content: ""; 
//         clear: both;  
//         display: table; 
//     } 
// } 





// // STATIC PAGES

// @mixin static-page-container {
//     padding: 0 10rem;

//     @media all and (max-width: 1250px) {
//         padding: 0 5rem;
//     }

//     @media all and (max-width: 650px) {
//         padding: 0rem;
//     }
// }






// .logged-in-header {
//     .header-logo {

//         margin-top: 1.6rem;
//         margin-left: 3rem;
//         width: 12.1rem;

//         &-follow {
//             font-size: 1.4rem !important;
//             font-weight: 400;
//             letter-spacing: .5px;
    
//             &-smtxt {
//                 font-size: 1rem !important;
//                 font-weight: 600;
//             }
//         }
    
//         &-investments {
//             font-size: 4.5rem !important;
    
//             &-dot {
//                 top: 4px;
//                 left: 1px;
//                 height: .9rem;
//                 width: .9rem;
//                 border-radius: 50%;
//                 background: $color-primary;
//             }
//         }
//     }
// }



// .info-message {
//     margin-top: 2rem;
//     font-size: 1.2rem;
//     color: $color-gray-dark;

//     i {
//         font-size: 2.4rem;
//         color: $color-primary;
//         padding-right: 1rem;
//         vertical-align: middle;
//     }
// }
