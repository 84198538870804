.dealerBenefits {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(30rem,1fr));
    grid-gap: 4rem;
    margin-top: 3rem;
    background: #fff;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 4rem;
    max-width: 1200px;
    margin: 0 auto 4rem auto;

    &Item {
        i, img {
            margin-bottom: 2rem;
            display: block;
            height: 4rem;
        }

        i {
            font-size: 4rem;
        }

        h4 {
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.6rem;
        }
    }
}