.logo {
    display: inline-block;
    // width: 32rem;
    text-align: right;

    &_follow {
        font-size: 1.6rem !important;
        font-weight: 400;
        color: #fefefe;
        line-height: 1;
        text-align: right;

        &_smtxt {
            font-size: 1.2rem !important;
            font-weight: 400;
        }

        &_blue {
            color: #469AD4;
        }

        @media all and (max-width: 700px) {
            font-size: 1.3rem !important;
        }
    }

    &_investments {
        font-size: 6rem !important;
        font-weight: 700;
        color: #fefefe;
        line-height: 1;
        display: block;
        margin-bottom: -3px;
        position: relative;

        &_dot {
            display: block;
            position: absolute;
            top: 7px;
            left: 2px;
            height: 1.2rem;
            width: 1.2rem;
            border-radius: 50%;
            background: #469AD4;
        }

        @media all and (max-width: 700px) {
            font-size: 4rem !important;
            margin-bottom: 0;

            &_dot {
                top: 4px;
                left: 1px;
                height: .8rem;
                width: .8rem;
            }
        }
    }
}