.companyGrowth {
    h4 {
        margin-bottom: 2rem;
    }

    height: 400px;

        margin-bottom: 6rem;
    
}
