.adminContentInvestors {

    h4 {
        margin-bottom: 2rem !important;
    }

    .investorsApproval  {
        margin-bottom: 40px;
    }
    
    .investTable {

        background: #fff;
        width: 100%;
        border-radius: 1rem;
            overflow: hidden;

        table {
            width: 100%;
            
        }

        thead {
            border-bottom: 1px solid #ccc;
            td {
                font-weight: bold;;
            }
        }
        

        tr:not(:last-child) {
            td {
                border-bottom: 1px solid #ccc;
            }
        }

        tbody tr:nth-child(odd) {
            background: #e7e7e7;
        }

        td {
            vertical-align: middle;
            padding: 10px;

            img {
                width: 50px;
                height: 50px;
                border-radius: 100%;
            }
        }
    }
  

}