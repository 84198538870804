.footer {
    font-size: 1.3rem;
    border-top: 1px solid #ededed;
    padding: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;

    @media print {
        display: none !important;
    }

    @media all and (max-width: 700px) {
        padding: 2rem;
        flex-direction: column;
    }

    &_copyright {
        @media all and (max-width: 700px) {
            order: 1;
            margin-top: 2rem;
        }
    }

    &_nav {
        text-align: right;

        @media all and (max-width: 700px) {
            text-align: center;
        }

        &_menu {
            a, button {
                padding: 5px;
                display: inline-block;
            }

            &:first-child {
                max-width: 300px;
                // border-bottom: 1px solid #e7e7e7;
                // margin-bottom: 2rem;
                // padding-bottom: 2rem;

                display: flex; 
                align-items: center; 
                justify-content: space-between;

                a {
                    padding: 0;
                    img {
                        width: 60px;
                    }

                    &:first-child {
                        img {
                            width: 160px;
                        }
                    }
                }
            }
        }
    }
}
