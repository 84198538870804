.buy_units {
    padding: 4rem;
    background: #fff;

    table {
        margin-bottom: 2rem;
        tr {
            td {

                font-size: 1.6rem;

                &:first-child { 
                    padding-right: 2rem;
                }

                &:last-child {
                    width: 18rem; 

                    input {
                        text-align: right;
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    .signature_font {
        font-family: 'Reenie Beanie', cursive;
        font-size: 3rem;
        display: inline-block;
        line-height: 1;
    }

    .modal_body_functions_agree-terms {
        padding: 1rem 0rem;

        label {
            line-height: 1.6;
        }
    }
}

.subscriptionPrice {
    margin-bottom: 3rem;

    h4 {
        font-weight: 400;
        color: #777;

        span {
            &:first-child {
                font-weight: 700;
                color: #444;
            }

            &:last-child {
                font-weight: 400;
                text-transform: initial;
                font-size: 14px;
                color: red;
            }
        }
    }
}

.noPrivateShares {
    display: block;
    text-align: center;
    margin: 0 auto;
    max-width: 360px;
    background: #E04C4C;
    color: #fff;
    padding: 1rem;
    border-radius: 1rem;
}

.investmentInfo {

    display: flex;

    @media all and (max-width: 900px) {
        flex-wrap: wrap;
    }

    table {
        border: 1px solid #e7e7e7;

        @media all and (max-width: 900px) {
            width: 100%;
        }
        
        &:first-child {
            margin-right: 2rem;
        }

        thead {
            td {
                font-weight: 700;
                background: #e7e7e7;
                color: #222 !important;
            }
        }

        tr {
            td {
                padding: 5px 10px;
                border: 1px solid #e7e7e7;

                &:first-child {
                    color: #888;
                }
            }
        }
    }
}


.consent_to_electronic_signature {
    background: #34495e;
    padding: 1rem 2rem;
    border-radius: 1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; 
    margin-bottom: 2rem;
    margin-left: -4rem;
    padding-left: 3.3rem;

    a {
        color: #fff !important;
        border-bottom: 1px solid #fff;
    }

    label {
        color: #fff !important;
        font-size: 1.3rem;
    }

    .modal_body_functions_agree-terms label span i {
        border: 1px solid #fff !important;
    }
}
