.create-account-nav {

    padding: 1rem 1.6rem;
    border-bottom: 1px solid #ededed;
    margin-bottom: -1px;

    &-title {
        width: 30%;
        display: inline-block;
        font-weight: 600;
        text-transform: uppercase;
        color: #555;
    }


    ul {

        display: inline-block;
        text-align: right;
        width: 70%;

        @media all and (max-width: 550px) {
            width: 90%;
        }

        li {
            display: inline-block;
            
            span {
                &:first-child {
                    border: 1px solid #ededed;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 1rem;
                    padding: .1rem .75rem;
                }
            }
        }
    }

    .status-current {
        span {
            color: #ffffff;
            background: #469AD4;
            border: 1px solid #469AD4;
        }
    }

    .status-complete {
        span {
            color: #ffffff;
            background: #6EC568;
            border: 1px solid #6EC568;
        }
    }

    .statu-incomplete {
        span {
            color: #ffffff;
            background: #ededed;
            border: 1px solid #ededed;
        }
    }
}
