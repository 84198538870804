.admin-add-investment {

    background: #fff;
    padding: 4rem;
    border-radius: 1rem;

    .row {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important; 
    }

    h4 {
        margin-bottom: 3rem;
    }
}