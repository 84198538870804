.App {
    max-width: 140rem;
    min-width: 42rempx;
    margin: 0 auto;
    -webkit-box-shadow: 0 0 1.6rem 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1.6rem 0 rgba(0, 0, 0, 0.2);
    position: relative;

    @media print {
        box-shadow: none;
    }
}
