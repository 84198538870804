.image-upload.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .image-upload__preview {
    // border: 1px solid #ccc;
    display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .image-upload__preview img {
    width: auto;
    height: 13rem;
    object-fit: cover;
    border-radius: 10px;
  }