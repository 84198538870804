.advertising {
    margin-top: 3rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    background: #fff;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto 4rem auto;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill,minmax(500px,1fr));

    @media (max-width: 600px) {
        grid-template-columns: repeat(auto-fill,minmax(320px,1fr));
    }

    .advertiseSection {
        background: #fff;
        padding-bottom: 4rem;
        border-radius: 1rem;
        overflow: hidden;
        border: 1px solid #e7e7e7;

        img {
            max-width: 918px;
            width: 90%;
            display: block;
            border-radius: 1rem;
            border: 1px solid #e7e7e7;
            margin: 4rem auto 0;
        }
    }

    .advertiseSectionHeader {
        -webkit-clip-path: polygon(0 0,100% 0,100% 90%,0 100%);
        clip-path: polygon(0 0,100% 0,100% 90%,0 100%);
        padding: 4rem;

        h2 {
            margin-bottom: 2rem;
            margin-top: 0 !important;
            text-align: left !important;
            font-size: 3rem;
        }

        p {
            margin-bottom: 2rem;
            font-size: 1.5rem;

            &.note {
                font-size: 1.3rem;
            }
        }

        table {
            margin-bottom: 2rem;

            tr {
                td {
                    font-size: 1.5rem;
                    border: 1px solid #fff;
                    padding: 1rem;

                    &:first-child {
                        width: 8rem;
                    }
                }
            }
        }
    }

    .advertiseSectionFeatured {
        .advertiseSectionHeader {
            background: #45b1bf;
            background: linear-gradient(to right bottom,#45b1bf,#1ebbb5);
            color: #fff!important;
        }
    }

    .advertiseSectionSponsored {
        .advertiseSectionHeader {
            background: #45b1bf;
            background: linear-gradient(to right bottom,#45b1bf,#3b97d3);
            color: #fff!important;
        }
    }

    .advertiseSectionGppv {
        .advertiseSectionHeader {
            background: #45b1bf;
            background: linear-gradient(to right bottom,#45b1bf,#894b9d);
            color: #fff!important;
        }
    }

    .advertisePricing {
        background: #fff;
        border-radius: 1rem;
        padding: 4rem;
        border: 1px solid #e7e7e7;

        h2 {
            margin-bottom: 3rem;
            margin-top: 0 !important;
            text-align: left !important;
            font-size: 3rem;
        }

        p:not(:last-child) {
            margin-bottom: 1rem;
        }

        table {
            margin-bottom: 4rem;
            width: 100%;

            td {
                border: 1px solid #e7e7e7;
                padding: 1rem;
                font-size: 1.5rem;
            }

            thead {
                td {
                    text-transform: uppercase;
                    font-weight: 700;
                    line-height: 1.2;
                    font-size: 1.2rem;
                    padding-top: 3rem;

                    &.thFeaturedBkg {
                        background: #45b1bf;
                        background: linear-gradient(to right bottom,#45b1bf,#1ebbb5);
                        color: #fff!important;
                    }

                    *.thSponsoredBkg {
                        background: #45b1bf;
                        background: linear-gradient(to right bottom,#45b1bf,#3b97d3);
                        color: #fff!important;
                    }
                }
            }
        }
    }
}