.startupList {
    margin-top: 3rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    max-width: 1200px;
    margin: 0 auto 4rem auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #fff;
    padding: 2rem;
    

    @media all and (max-width: 1300px) {
        max-width: 90%;
    }

    .startupListBox {
        width: 48.5%;
        background: #fff;
        padding-bottom: 2rem;
        margin-bottom: 4rem;
        border-radius: 1rem;
        overflow: hidden;
        border: 1px solid #e7e7e7;

        @media all and (max-width: 900px) {
            width: 100%;
        }

        img {
            width: 100%;
            margin-bottom: 3rem;
        }

        h4 {
            margin: 0 2rem;
            margin-bottom: 2rem;

            i {
                font-size: 3rem;
                margin-right: 1rem;
                margin-left: 1rem;
                vertical-align: middle;
                display: inline-block;
                border-right: 1px solid #80dc7b;
                border-left: 1px solid #80dc7b;
                width: 60px;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        ul {
            margin: 0 2rem;

            li {
                padding: 1.4rem 0 1.4rem 1.2rem;
                font-size: 1.4rem;
                border-bottom: 1px solid #ededed;

                &:last-child {
                    border-bottom: 0;
                }

                i {
                    color: #6ec568;
                    margin-right: 1rem;
                }
            }
        }

        p {
            padding: 1rem 3.2rem;
            color: #777;
        }
    }
}