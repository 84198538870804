.upcomingUpdates {
    h4 {
        margin-bottom: 2rem;
    }
}

.press {
    border-radius: 1rem;
    margin-bottom: 2rem;
    background: #fff;
    display: flex;
    align-items: flex-start;

    @media all and (max-width: 700px) {
        flex-wrap: wrap;
    }

    h4 {
        line-height: 1.2;
        margin: 0;
    }

    p {
        margin-bottom: 1rem;
    }

    .prImage {
        width: 30%;
        border-radius: 1rem;
        display: block;

        @media all and (max-width: 700px) {
            width: 70%;
        }
    
    }

    .prContent {
        width: 65%;
        margin-left: 5%;

        @media all and (max-width: 700px) {
            width: 100%;
            margin-left: 0;
            margin-top: 4rem;
        }

        .prMeta {
            font-size: 1.2rem;
        }
        
        .prContact {
            margin-bottom: 3rem;
            margin-top: 2rem;
            display: block;
            width: 30rem;
            border: 1px solid #ededed;
            border-radius: 1rem;
            padding: 2rem;

            span {
                color: #b5b5b5;
            }
        }
    }
}