.profile-settings {

    padding-bottom: 4rem;
    background: #f4f4f4;

    h4 {
        margin: 0 auto;
        margin-top: 2rem;
        width: 96%;

        @media all and (max-width: 1100px) {
            width: 100%;
            margin-top: 0;
            padding: 2rem 5% 0 5%;
        }
    }

    &-text {
        padding: 1.6rem 3.6rem;
        display: block;
    }

    &-box {

        padding: 2rem;

        &-open {
            display: block;
        }

        &-closed {
            display: none;
        }
    }

    .create-account-content-box {

        background: #fff;
        width: 96%;
        overflow: hidden;
        margin: 0 auto;
        margin-top: 2rem;
        border: 1px solid #f4f4f4;

        @media all and (max-width: 1100px) {
            width: 90%;
            margin: 2rem 5% 0rem 5%;
            box-sizing: border-box;
        }

        &-label {
            font-size: 1.3rem;
            padding: 1.5rem 2rem;
            margin-left: 0px;
            border-left: 0px;
            margin-top: -1px;
            margin-bottom: -1px;
            width: 100%;
            text-align: left;
            color: #333;
            border-top: 2px solid #f8f8f8;

            i {
                padding-right: 1rem;
            }

            &:hover,
            &-active {
                background-color: #65A2E7 ;
                color: #fff;
            }
        }

        .update-accreditation-checkbox {
            border-top: 1px solid #e7e7e7;
            padding-top: 1rem;
            margin-top: 2rem;
        }

        li {
            list-style-type: disc;
            margin-left: 4rem;
            &:not(:last-child) {
                margin-bottom: .8rem;
            }
        }

        .button-holder {
            margin-top: 2rem;
        }
    }
}

