.accountPageSidebar {
    width: 22%;
    display: inline-block;
    margin-left: 3%;
    vertical-align: top;
    margin-top: 20.2rem;
    position: relative; 
    z-index: 92;

    @media all and (max-width: 1400px) {
        width: 25%;
        margin-left: 2%;
    }

    @media all and (max-width: 1270px) {
        width: 30%;
        margin-left: 2%;
    }

    @media all and (max-width: 1100px) {
        width: 100%;
        margin-left: 0;
        margin-top: 2rem;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

   

    &Box {
        padding: 2rem; 
        background: #fff; 
        width: 100%;
        border-radius: 1rem;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        @media all and (max-width: 1100px) { 
            width: 32%;

            &:not(:last-child) {
                margin-bottom: 0rem;
            }
        }

        @media all and (max-width: 980px) {
            width: 49%;  
            
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }

        @media all and (max-width: 670px) {
            width: 100%;  
        }


        h4 {
            font-size: 1.5rem;
            line-height: 1.2;
            margin-bottom: 2rem;
        }

        .row {
            text-align: center;
            button {
                padding: 1rem 2rem;
            }
        }

        .note {
            font-size: 1.1rem;
            margin-top: 1.6rem;
            color: #999;

            i {
                font-size: 1.4rem;
                padding-right: .5rem;
                color: #EE6565;
            }
        }

        table {

            width: 100%;

            tr {
                td {
                    padding: .9rem 0;
                    border-bottom: 1px solid #ededed;
                    vertical-align: middle;

                    &:first-child {
                        color: #999999;
                        padding-right: 1rem;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }

                &:last-child {
                    td {
                        border-bottom: 0 !important;
                    }
                }
            }
        }


        .sidebarActionBox {
            text-align: center;

            i {
                display: block;
                font-size: 4rem;
                color: #999;
                margin-bottom: 2rem;
            }

            p {
                font-size: 1.6rem;
                line-height: 1.3;
            }

            button, a {
                margin-top: 2rem;
            }
        }
    }
}