.menu {
    position: fixed;
    right: 0; 
    top: 0;
    width: 70rem;
    height: 100%;
    background: #469ad4;
    z-index: 9999999;
    padding-left: 8rem;

    @media all and (max-width: 1400px) {
        width: 50%;
    }

    @media all and (max-width: 1100px) {
        padding-left: 4rem;
    }

    @media all and (max-width: 600px) {
        width: 80%;
    }

    -webkit-box-shadow: -7px 0px 24px -5px rgba(0,0,0,0.3);
    -moz-box-shadow: -7px 0px 24px -5px rgba(0,0,0,0.3);
    box-shadow: -7px 0px 24px -5px rgba(0,0,0,0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    .navigation_button {
        position: absolute;
        top: 2.6rem;
        right: 3.5rem;
        z-index: 99999;
        border: 0;
        background: transparent;
        font-size: 4rem;
        color: #fefefe;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
            color: #fefefe;
            font-size: 4.4rem;
            top: 2.4rem;
            right: 3.3rem;
        }
    }

    ul {

        width: 100%;

        li {

            h4 {
                font-size: 2rem;
                font-weight: 900;
                color: #fff;
                margin-bottom: 1rem;

                @media all and (max-width: 1100px) {
                    font-weight: 600;
                }

                @media all and (max-width: 700px) {
                    font-size: 1.6rem;
                }
            }

            a {
                color: #fff;
                display: inline-flex;
                align-items: center;
                font-size: 3.4rem;
                line-height: 1.2;
                font-weight: 100;
                transition: all .3s ease-in-out;
                margin-bottom: .2rem;
                text-decoration: none;

                i {
                    display: inline-flex;
                    width: 50px;
                    height: 50px;
                    transition: all .3s ease-in-out;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 2rem;
                    margin-right: 1rem;
                    border: 1px solid transparent;
                    border-radius: 1rem;
                }

                @media all and (max-width: 1100px) {
                    font-size: 3rem;
                    font-weight: 300;
                }

                @media all and (max-width: 700px) {
                    font-size: 2.4rem;
                    line-height: 1.3;
                }

                &:hover,
                &:active,
                &.active {
                    i {
                        border: 1px solid #fff !important;
                    }
                }
            }
        }
    }
}



