.create-account-content-box {
    width: 70%;
    background: #fff;
    border-radius: 1rem;
    margin-bottom: 4rem;
    margin: 2rem auto;

    @media all and (max-width: 550px) {
        width: 90%;
    }

    .create-account-content-verification {
        border-top: 1px solid #ededed;
        padding: 1rem;
    }

    .accreditation-disclaimer {
        padding: 2rem;

        p {
            margin-bottom: 0 !important;
        }
    }

    .accreditation-list {
        padding: 0 2rem 2rem 2rem;
        
        li {
            list-style-type: disc;
            margin-left: 4rem;
            &:not(:last-child) {
                margin-bottom: .8rem;
            }
        }
    }

    .accreditation-acceptance {
        border-top: 1px solid #e7e7e7;
        margin-top: 2rem;
        padding: 2rem;
        margin: 0 auto;

        label {
            button {
                padding: 0 1rem;
                margin-right: 0 !important;
            }
        }

    }

    .sing-up-question {
        padding: 1rem 2rem;
    }

 
    h4 {
        font-size: 1.5rem;
        line-height: 1.2;
        padding-bottom: .5rem;
        border-bottom: 1px solid #ededed;
    }

    .row {
        padding: 0 2rem;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    }

    &-label {
        font-size: 1.1rem;
        color: #999;
        padding: .5rem 1.6rem;
        display: block;
        margin-left:-5px;
        border-left: 5px solid #999;
        border-bottom: 1px solid #ededed;
        border-top: 1px solid #ededed;
        margin-top: -1px;
        background: #f8f8f8;
        margin-bottom: 1rem;

        &:not(:first-child) {
            margin-top: 2rem;
        }
    }


    .modal-body-functions-agree-terms {
        padding-bottom: 0 !important;
    }


    .button-holder-previous {
        text-align: left;
        display: inline-block;
        width: 50%;

        button {
            margin: 2rem;
        }
    }

    .button-holder-next {
        text-align: right;
        display: inline-block;
        width: 50%;

        button {
            margin: 2rem;
        }
    }

    .button-holder {
        text-align: center;

        button {
            margin: 2rem;
        }
    }

    .add-investments-button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        button {
            display: block;
            font-size: 2.4rem;
            padding: 1rem;
        }
    }
}