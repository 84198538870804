.page {
    padding: 4rem;
    background: #fff;

    h4 {
        margin-bottom: 2rem;
    }
}

.table {

    border-radius: 1rem;
    overflow: hidden;

    tr {
        td {
            padding: 10px 20px;
            border: 1px solid #e7e7e7;
        }
    }

    thead {
        tr {
            td {
                color: #fff;
                background: #444;
                font-weight: strong;
            }
        }
    }
}