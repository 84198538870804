.adminContentTransactions {
    max-width: 1278px;
    overflow-x: scroll;

    .helpNav {
        background: #fff;
        padding: 1rem 2rem;
        border-radius: 1rem;
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        form {
            display: flex;
            align-items: center;

            div, button {
                margin-left: 3rem;
            }
        }
    }

    .outstandingTotalShare {
        background: #333;
        color: #fff;
        padding: 20px;
        margin-bottom: 20px;
        display: flex;
    
        p {
            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    
        span {
            display: block;
            font-size: 20px;
            font-weight: 700;
            line-height: 1;
        }
    }


   .transactionsContainer {

        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr)); 

        &Box {
            background: #fff;
            padding: 20px;
            border-radius: 10px;
            
            p {
                padding: .4rem;

                &:not(:last-child) {
                    border-bottom: 1px solid #e7e7e7;
                }

                span {
                    &:first-child {
                        display: inline-block;
                        width: 180px;
                        text-align: left !important;
                        color: #999;
                    }

                    &:last-child {
                        display: inline-block;
                        font-weight: 700;
                    }
                }
            }
        }
   }
}


.transactionContainerList {

    border-spacing: 0;
    border-collapse: collapse;
    background: #fff;
    margin-bottom: 4rem;

    tr {
        td {
            border: 1px solid #e7e7e7;
            padding: 10px 12px;

            &.rightAl {
                text-align: right;
            }

            a {

                &:not(:last-child) {
                    margin-right: 1rem;
                }

                i {
                    font-size: 2.4rem;
                }
            }
        }
    }
}