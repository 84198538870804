.rounds-container {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(40rem,1fr));
    grid-gap: 2rem;
    margin-top: 2rem;
}

.round-box {
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;

    table {
        margin-top: 1rem;

        td {
            border: 1px solid #ccc;
            padding: 1rem;

            &:last-child {
                font-weight: bold;
            }
        }
    }
}

.summary-table {
    margin-top: 1rem;
    background: #fff;

    td {
        border: 1px solid #ccc;
        padding: 1rem;
        font-size: 1.6rem;

        &:last-child {
            font-weight: bold;
        }
    }
}