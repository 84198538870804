.header {

    @media print {
        display: none !important;
    }

    .navigation_button {
        position: absolute;
        top: 2.6rem;
        right: 3.5rem;
        z-index: 99999;
        border: 0;
        background: transparent;
        font-size: 4rem;
        color: #fefefe;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
            color: #fefefe;
            font-size: 4.4rem;
            top: 2.4rem;
            right: 3.3rem;
        }

        &_close {
            color: #fefefe;

            &:hover {
                color: #fefefe;
            }
        }
    }
}


