.benefitBox {
    margin-top: 3rem;
    background: #fff;
    max-width: 1200px;
    margin: 0 auto 4rem auto;
    display: flex;
    border-radius: 1rem;

    &.firstChild {
        border-top-right-radius: 0rem;
        border-top-left-radius: 0rem;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    
    @media all and (max-width: 700px) {
        flex-wrap: wrap;
    }

    .benefitBoxList {
        border-right: 1px solid #e7e7e7;
        width: 400px;
        padding: 4rem;

        @media all and (max-width: 900px) {
            padding: 2rem;
            width: 50%;
        }

        @media all and (max-width: 700px) {
            width: 100%;
            order: 0;
            border-left: 0;
        }

        h3 {
            font-size: 2.8rem;
        }

        table {
            tr {
                td {
                    vertical-align: top;
                    padding-top: 2rem;

                    &:first-child {
                        font-size: 2.4rem;
                        padding-right: 2rem;
                        text-align: center;
                    }
                }
            }
        }
    }
    
    .benefitBoxListRight {
        order: 2;
        border-right: 0;
        border-left: 1px solid #e7e7e7;
        justify-content: flex-end;

        @media all and (max-width: 700px) {
            width: 100%;
            order: 0;
            border-left: 0;
        }
    }

    .benefitBoxImg {
        flex: 1 1;
        text-align: center;
        padding: 4rem;

        @media all and (max-width: 900px) {
            padding: 2rem;
            width: 50%;
        }

        img {
            width: 80%;
        }
    }
}