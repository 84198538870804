.accountPage {
    position: relative;

    &Title {
        padding: 2rem 0 0 0;
        margin-bottom: -2.6rem;
        color: #fff;
        z-index: 99; 
        position: relative;
        text-align: center;
        font-size: 2.2rem;
        transform: translateY(70px);

        @media all and (max-width: 650px) {
            margin-bottom: -8rem;
        }
    }
}


.accountPageContainer {
    padding: 4rem;
    background: #f4f4f4;

    @media all and (max-width: 500px) {
        padding: 2rem;
    }
}



.accountPageContent {
    width: 75%;
    display: inline-block;
    vertical-align: top;
    margin-top: 15rem;

    @media all and (max-width: 1400px) {
        width: 73%;
    }

    @media all and (max-width: 1270px) {
        width: 68%;
    }

    @media all and (max-width: 1100px) {
        width: 100%;
    }

    position: relative;
    z-index: 92;

    &Nav {
        display: flex; 
        justify-content: center;

        @media all and (max-width: 1400px) {
            justify-content: flex-end;
            margin-right: 2rem;
        }

        @media all and (max-width: 800px) {
            justify-content: center;
            margin-right: 2rem;
            margin-right: 0;
            flex-wrap: wrap;
        }

        button {
            color: #fff;
            font-size: 1.2rem;
            line-height: 1.2;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            display: flex;
            align-items: center;
            padding: 1.2rem 2.2rem;
            text-align: left;

            @media all and (max-width: 650px) {
                border-radius: 1rem;
                margin-bottom: 1rem;
                border: 1px solid #fff;
                width: 88px;
                padding: 1.4rem 0 1.2rem 0;
                display: inline-block;
                text-align: center;

                &:not(:last-child) {
                    margin-right: 1rem;  
                }
            }

            i {
                font-size: 2.6rem;
                margin: 0 auto;
                padding-right: 1rem;

                @media all and (max-width: 650px) {
                    display: block;
                    margin-bottom: .5rem;
                    padding: 0;
                    font-size: 2rem;
                }
            }

            &.active,
            &:hover  {
                background: #fff;
                color: #555;
            }
        }
    }

    &Box {
        background: #fff;
        padding: 3rem;
        border-radius: 1rem;

        p {
            display: block;
            // height: 7rem;
        }
    }


    .historyInvestments {
        h4 {
            margin-bottom: 2rem;
        }
    }

    .stockChart {
        h4 {
            margin-bottom: 2rem;
        }
    }

    .futurePlans {
        h4 {
            margin-bottom: 2rem;
        }
    }
}



.dashboardGradient {
    background: #469AD4;
    background: -webkit-linear-gradient(to right bottom,#f77062,#469AD4);
    background: -webkit-gradient(linear,left top,right bottom,from(#f77062),to(#469AD4));
    background: -webkit-linear-gradient(left top,#f77062,#469AD4);
    background: -o-linear-gradient(left top,#f77062,#469AD4);
    background: linear-gradient(to right bottom,#f77062,#469AD4);

    width: 100%;
    height: 395px;

    position: absolute;
    top: 0;
    left: 0;

    z-index: 91;
}










