.transactionsContainer {
    padding: 4rem;
    
    .roundTransaction {

        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        &Title {
            font-weight: 600;
            color: #555;
            font-size: 1.8rem;
        }

        &Item {

            background: #fff;
            border-radius: 1.2rem;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 4rem;
            }
        }
    }

    &Bought {
        display: flex;
        border-right: 1px solid #ededed;
        background: #333;
        color: #fff;

        @media all and (max-width: 999px) {
            flex-wrap: wrap;
        }

        div {
            width: 12%;
            font-size: 1.5rem;
            line-height: 1.2;
            border: 1px solid #555;
            border-right: 0;
            padding: 1rem;

            display: flex;
            flex-direction: column;
            justify-content: center;

            @media all and (max-width: 1150px) {
                font-size: 1.3rem;
            }

            @media all and (max-width: 999px) {
                width: 25%;   
                margin-bottom: -1px;
            }

            @media all and (max-width: 700px) {
                width: 50%;   
            }

            &:nth-child(8) {
                @media all and (max-width: 999px) {
                    width: 12.5%;   
                } 

                @media all and (max-width: 700px) {
                    width: 34%;   
                }
            }

            &:last-child {
                width: 4.35%;
                text-align: center;

                @media all and (max-width: 999px) {
                    width: 12.5%;   
                } 

                @media all and (max-width: 700px) {
                    width: 16%;   
                }
            }

            span {
                display: block;
                font-size: 1.2rem;
                color: #ccc;
            }

            button, a {
                i {
                    font-size: 3.3rem;
                }

                color: #fff;

                &.button {
                    width: 100%;
                }
            }
        }
    }



    &Sell {
        display: flex;
        align-items: center;
        border: 1px solid #ededed;
        margin-top: -1px;
        padding: 1rem;

        @media all and (max-width: 999px) {
            margin-top: 0;
        }

        div {
            &:not(:last-child) {
                margin-right: 2rem;
            }
        }

        .message {
            font-style: italic;
            color: #999;
        }
    }



    &Sold {
        display: flex;
        align-items: center;
        border-left: 1px solid #ededed;

        .arrow {
            width: 5%;
            text-align: center;

            @media all and (max-width: 999px) {
                width: 8%;
            }

            i {
                font-size: 3rem;
                color: #999;

                @media all and (max-width: 999px) {
                    font-size: 2rem;  
                }
            }
        }

        &Transactions {
            width: 95%;

            @media all and (max-width: 999px) {
                width: 92%;
            }

            &Row {
                display: flex;
                border-right: 1px solid #ededed;
            
                &:not(:last-child) {
                    margin-bottom: -1px;
                }

                @media all and (max-width: 999px) {
                    flex-wrap: wrap; 

                    &:not(:last-child) {
                        border-bottom: 1px solid #ededed;
                    }
                }

                div {
                    width: 12%;
                    font-size: 1.5rem;
                    line-height: 1.2;
                    border: 1px solid #ededed;
                    border-right: 0;
                    padding: 1rem;

                    @media all and (max-width: 1100px) {
                        font-size: 1.3rem;
                    }

                    @media all and (max-width: 999px) {
                        width: 20%;
                        border: 0;
                    }

                    @media all and (max-width: 700px) {
                        width: 50%;
                    }

                    

                    &:last-child {
                        width: 5%;
                        text-align: center;

                        @media all and (max-width: 999px) {
                            width: 20%;
                            text-align: left;
                        }

                        @media all and (max-width: 700px) {
                            width: 50%;
                        }
                    }

                    span {
                        display: block;
                        font-size: 1.2rem;
                        color: #999;
                    }

                    button {
                        i {
                            font-size: 3.3rem;
                        }
                    }
                }
            }
        }
    }
}