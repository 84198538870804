.logged_in_header {
    width: 100%;
    background: #121212;
    height: 10rem;
    z-index: 99;
    position: relative;
    padding-left: 20px;
    display: flex;
    align-items: center;

    @media print {
        display: none !important;
    }
}