.transactions {

    background: #f4f4f4;

    h4 {
        padding: 2rem 0 0 4rem;
        margin-bottom: -2rem;
    }
}

.investmentList {
    padding: 2rem 4rem;
    display: grid; 
    grid-gap: 20px; 
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-top: 2rem;
}

