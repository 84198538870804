.subscriptionAgreement {
    background: #fff;
    padding-bottom: 4rem;

    h4.mainTitle {
        padding: 2rem 0 0 4rem;
        margin-bottom: -2rem;
    }

    &Document {
        background: #ffffff;
        padding: 4rem;
        border-radius: 1rem;

        .inputValue {
            font-weight: bold;
            border-bottom: 1px solid #444444;
            padding: 0 1rem;
            vertical-align: middle;
            display: inline-block;
        }

        .inputSelected {
            width: 4rem;
            display: inline-block;
            border-bottom: 1px solid #444444;
            margin-right: 1rem;
            text-align: center;
            font-weight: bold;
            font-size: 1.6rem;
            text-transform: uppercase;
        }

        .personalSignature {
            font-family: 'Reenie Beanie', cursive;
            font-size: 3rem;
            border-bottom: 1px solid #444444;
            display: inline-block;
            padding: 0 1rem;
            line-height: 1;
            margin-bottom: .5rem;
        }

        .signatureFont {
            font-family: 'Reenie Beanie', cursive;
            font-size: 3rem;
            padding-bottom: 1rem;
        }

        h4, h3 {
            text-align: center;
            line-height: 1.2;
        }

        h3 {
            margin-bottom: 3rem;
        }

        p {
            &:not(:last-child) {
                margin-bottom: 1.6rem;
            }
        }

        ul, ol {
            margin-bottom: 1.6rem;

            li {
                &:not(:last-child) {
                    margin-bottom: 1.6rem;
                }
            }
        }

        table {
            margin-bottom: 1.6rem;

            tr {
                td {
                    &:first-child {
                        padding-right: 2rem;
                    }
                }
            }
        }

        .indent {
            margin-left: 4rem;
            margin-bottom: 1.6rem;
        }

        ol {

            &.typeI {
                list-style-type: lower-roman;
            }

            &.typeA {
                list-style-type: lower-alpha;
            }
            

            li {
                margin-left: 4rem;
            }
        }

        ul {
            &.typeDisc {
                list-style-type: disc !important;
            }

            li {
                margin-left: 4rem;
            }
        }

        &Footnote {
            border-top: 1px solid #444444;
            padding-top: 2rem;
            margin-top: 4rem;

            p {
                margin-bottom: .6rem !important;
                font-size: 1.1rem;
            }
        }
    }
}


