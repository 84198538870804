.pending-investments {
    table {

        thead {
            tr {
                td {
                    border: 1px solid #444;
                    background: #444;
                    color: #fff;
                }
            }
        }

        tbody {
            tr {

                &:nth-child(even) {
                    td {
                        background: #f8f8f8
                    }
                }

                td {

                }
            }
        }

        tr {
            td {
                padding: 1rem;
                border: 1px solid #e7e7e7;
            }
        }
    }
}